import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'service',
    path: '/service/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'user-doctor-message',
        title: translation.getTranslation('PNL_NAV_SERVICES', 'Services'),
        activeLink: 'service'
      },
      isSecured: true,
      hasAnyRoles: ['ADMIN_SERVICE']
    },
    redirect: '/service/list/',
    routes: [
      {
        name: 'serviceList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_SERVICE_LIST', 'Service List'),
            activeLink: 'serviceList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SERVICE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/service/ServiceListPage.vue')
      }
    ]
  }
]

export default routes
