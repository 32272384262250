import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'booking',
    path: '/booking/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'bookmark',
        title: translation.getTranslation('PNL_NAV_BOOKINGS', 'Bookings'),
        activeLink: 'booking'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_BOOKING']
    },
    redirect: '/booking/list/',
    routes: [
      {
        name: 'bookingList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_BOOKING_LIST', 'Booking List'),
            activeLink: 'bookingList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_BOOKING']
        },
        beforeEnter: [checkMeta],
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/booking/BookingListPage.vue')
      },
      {
        name: 'bookingList',
        path: 'cancellation/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Booking Cancellation List',
            activeLink: 'bookingCancellationList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_BOOKING']
        },
        beforeEnter: [checkMeta],
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/booking/BookingCancellationListPage.vue')
      }
    ]
  }
]

export default routes
