import Framework7 from 'framework7'
import Framework7Vue, { registerComponents } from 'framework7-vue/bundle'

// FRAMEWORK7 STYLING
import 'framework7/css'

// import Framework7 components
import Dialog from 'framework7/components/dialog'
import 'framework7/components/dialog/css'

import Popup from 'framework7/components/popup'
import 'framework7/components/popup/css'

import Popover from 'framework7/components/popover'
import 'framework7/components/popover/css'

import Actions from 'framework7/components/actions'
import 'framework7/components/actions/css'

import Sheet from 'framework7/components/sheet'
import 'framework7/components/sheet/css'

import Toast from 'framework7/components/toast'
import 'framework7/components/toast/css'

import Preloader from 'framework7/components/preloader'
import 'framework7/components/preloader/css'

import Sortable from 'framework7/components/sortable'
import 'framework7/components/sortable/css'

import Progressbar from 'framework7/components/progressbar'
import 'framework7/components/progressbar/css'

import Panel from 'framework7/components/panel'
import 'framework7/components/panel/css'

import Chip from 'framework7/components/chip'
import 'framework7/components/chip/css'

import Form from 'framework7/components/form'
import 'framework7/components/form/css'

import Input from 'framework7/components/input'
import 'framework7/components/input/css'

import Checkbox from 'framework7/components/checkbox'
import 'framework7/components/checkbox/css'

import Radio from 'framework7/components/radio'
import 'framework7/components/radio/css'

import Toggle from 'framework7/components/toggle'
import 'framework7/components/toggle/css'

import Grid from 'framework7/components/grid'
import 'framework7/components/grid/css'

import Calendar from 'framework7/components/calendar'
import 'framework7/components/calendar/css'

import Picker from 'framework7/components/picker'
import 'framework7/components/picker/css'

import InfiniteScroll from 'framework7/components/infinite-scroll'
import 'framework7/components/infinite-scroll/css'

import PullToRefresh from 'framework7/components/pull-to-refresh'
import 'framework7/components/pull-to-refresh/css'

import DataTable from 'framework7/components/data-table'
import 'framework7/components/data-table/css'

import Searchbar from 'framework7/components/searchbar'
import 'framework7/components/searchbar/css'

import Notification from 'framework7/components/notification'
import 'framework7/components/notification/css'

import Tooltip from 'framework7/components/tooltip'
import 'framework7/components/tooltip/css'

import PhotoBrowser from 'framework7/components/photo-browser'
import 'framework7/components/photo-browser/css'

import Swiper from 'framework7/components/swiper'
import 'framework7/components/swiper/css'

import TextEditor from 'framework7/components/text-editor'
import 'framework7/components/text-editor/css'

import Treeview from 'framework7/components/treeview'
import 'framework7/components/treeview/css'

import SmartSelect from 'framework7/components/smart-select'
import 'framework7/components/smart-select/css'

import Messages from 'framework7/components/messages'
import 'framework7/components/messages/css'

import Messagebar from 'framework7/components/messagebar'
import 'framework7/components/messagebar/css'

import Breadcrumbs from 'framework7/components/breadcrumbs'
import 'framework7/components/breadcrumbs/css'

Framework7.use([
  Actions,
  Chip,
  Dialog,
  Popup,
  Preloader,
  Sortable,
  Progressbar,
  Popover,
  Form,
  Input,
  Checkbox,
  Radio,
  Toggle,
  Grid,
  Calendar,
  Picker,
  InfiniteScroll,
  PullToRefresh,
  DataTable,
  Searchbar,
  Notification,
  Tooltip,
  Panel,
  PhotoBrowser,
  Sheet,
  Swiper,
  Toast,
  TextEditor,
  Treeview,
  SmartSelect,
  Messages,
  Messagebar,
  Breadcrumbs
])

Framework7.use(Framework7Vue)

export { registerComponents, Framework7, Framework7Vue }
