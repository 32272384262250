<template>
  <f7-popup id="editProfilePopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="$t.getTranslation('PNL_EDIT_PROFILE')" @close-popup="closePopup" />
      </template>

      <FormComponent id="EditProfilePartial" ref="formComponent" @success-save="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "EditProfilePartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    const formComponentData = {
      formId: "EditProfilePartial",
      buttonText: "",
      getLink: "/user/view",
      getParams: {},
      validation: "profile",
      saveLink: "/user/save",
      saveParams: {},
      successUpdateMessage: "Successfully updated profile information.",
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("PNL_PRIMARY_INFORMATION"),
        type: "input",
        isRow: true,
        list: [
          { name: $t.getTranslation("PNL_FIRST_NAME"), type: "text", value: "FirstName", required: true, rowWidth: "col-50" },
          { name: $t.getTranslation("PNL_LAST_NAME"), type: "text", value: "LastName", required: true, rowWidth: "col-50" },
          { name: $t.getTranslation("PNL_BIRTH_DATE"), type: "date", value: "BirthDate", required: false, hideClearButton: true, rowWidth: "col-100" },
          {
            name: $t.getTranslation("PNL_GENDER"),
            type: "select",
            value: "Gender",
            required: false,
            hideClearButton: true,
            items: store.getters["config/getData"]?.genderList,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: "",
            rowWidth: "col-100",
          },
        ],
      },
    ];

    return {
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup() {
      const self = this;

      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);
      self.formComponentData.buttonText = self.$t.getTranslation("PNL_UPDATE");

      await self.$refs.formComponent.setFormComponentData(self.formComponentData, true);

      f7.popup.open("#editProfilePopup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      const self = this;

      if (data?.isSave) {
        store.dispatch("user/fetchData", { force: true });
      }

      f7.popup.close("#editProfilePopup");
      self.$emit("closed", data);
    },
  },
});
</script>
