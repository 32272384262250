import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'payment',
    path: '/payment/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'money-check-dollar',
        title: translation.getTranslation('PNL_NAV_PAYMENTS', 'Payments'),
        activeLink: 'payment'
      },
      isSecured: true,
      hasRoles: ['ADMIN_PAYMENT']
    },
    redirect: '/payment/processed/list/',
    routes: [
      {
        name: 'paymentProcessedList',
        path: 'processed/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Processed Payments',
            activeLink: 'paymentProcessedList'
          },
          hasRoles: ['ADMIN_PAYMENT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/payment/admin/PaymentProcessedPage.vue')
      },
      {
        name: 'paymentUnprocessedList',
        path: 'unprocessed/list/',
        meta: {
          navigation: {
            isShown: true,
            title: 'Unprocessed Payments',
            activeLink: 'paymentUnprocessedList'
          },
          hasRoles: ['ADMIN_PAYMENT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/payment/admin/PaymentUnprocessedPage.vue')
      }
    ]
  }
]

export default routes
