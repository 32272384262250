import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'payment',
    path: '/payment/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'money-check-dollar',
        title: translation.getTranslation('PNL_NAV_PAYMENTS', 'Payments'),
        activeLink: 'payment'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_PAYMENT']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/payment/PaymentListPage.vue')
  }
]

export default routes
