import { helpers } from '@/utils/helpers.js'
import { translation } from '@/utils/translation.js'

const validations = {
  validPassword: function (password) {
    if (password && password.length >= 6 && password.length <= 20) {
      return true
    } else {
      return false
    }
  },
  validEmail: function (email) {
    let regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  },
  required: (requiredFields, validationData) => {
    for (let i = 0; i < requiredFields.length; i++) {
      let item = requiredFields[i]
      if (helpers.isBlank(validationData[item])) {
        throw {
          Code: 'ERR_MISSING_FIELD',
          Message: `${item} ${translation.getTranslation('ERR_FIELD_IS_REQUIRED')}`
        }
      }
    }

    return validationData
  },
  requiredFields: (required, check) => {
    let ret = {
      missing: [],
      error: false,
      values: {}
    }

    required.forEach(function (f) {
      if (!check[f]) {
        ret.missing.push(f)
      } else {
        ret.values[f] = check[f]
        delete check[f]
      }
    })

    let keys = Object.keys(check)
    keys.forEach(function (k) {
      ret.values[k] = check[k]
    })

    if (ret.missing.length > 0) {
      throw {
        Code: 'ERR_MISSING_FIELDS',
        Message: `${translation.getTranslation('ERR_REQUIRED_FIELDS_ARE_MISSING')} ${ret.missing.join(', ')}`
      }
    }
    return ret.values
  },
  validate: function ({ validateData, validationRules, throwError = false, showNotifications = false }) {
    for (let key in validateData) {
      if (key && validationRules && validationRules[key]) {
        if (validationRules[key].integer && !helpers.isBlank(validationRules[key].integer)) {
          if (!helpers.isBlank(validateData[key]) && !helpers.isNumber(validateData[key])) {
            if (throwError) {
              throw {
                Code: 'ERR_INVALID_INTEGER_FIELD',
                Message: `${key} ${translation.getTranslation('ERR_FIELD_IS_INVALID')}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${key} ${translation.getTranslation('ERR_FIELD_IS_INVALID')}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].required && !helpers.isBlank(validationRules[key].required) && validationRules[key].required === true) {
          if (helpers.isBlank(validateData[key])) {
            if (throwError) {
              throw {
                Code: 'ERR_MISSING_FIELDS',
                Message: `${key} ${translation.getTranslation('ERR_FIELD_IS_REQUIRED')}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${key} ${translation.getTranslation('ERR_FIELD_IS_REQUIRED')}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].minimum && !helpers.isBlank(validationRules[key].minimum) && validationRules[key].minimum > 0) {
          if (!helpers.isBlank(validateData[key]) && parseFloat(validateData[key]) < validationRules[key].minimum) {
            if (throwError) {
              throw {
                Code: 'ERR_MINIMUM_AMOUNT_INVALID',
                Message: `${translation.getTranslation('ERR_MINIMUM_AMOUNT_NOT_MET')} ${validationRules[key].minimum}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${translation.getTranslation('ERR_MINIMUM_AMOUNT_NOT_MET')} ${validationRules[key].minimum}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].maximum && !helpers.isBlank(validationRules[key].maximum) && validationRules[key].maximum > 0) {
          if (!helpers.isBlank(validateData[key]) && parseFloat(validateData[key]) > validationRules[key].maximum) {
            if (throwError) {
              throw {
                Code: 'ERR_MAXIMUM_AMOUNT_INVALID',
                Message: `${translation.getTranslation('ERR_MAXIMUM_AMOUNT_EXCEEDED')} ${validationRules[key].maximum}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${translation.getTranslation('ERR_MAXIMUM_AMOUNT_EXCEEDED')} ${validationRules[key].maximum}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].minimumLength && !helpers.isBlank(validationRules[key].minimumLength) && validationRules[key].minimumLength > 0) {
          if (!helpers.isBlank(validateData[key]) && parseInt(validateData[key].length) < validationRules[key].minimumLength) {
            if (throwError) {
              throw {
                Code: 'ERR_MINIMUM_CHARACTER_LENGTH_INVALID',
                Message: `${translation.getTranslation('ERR_MINIMUM_CHARACTER_NOT_MET')} ${validationRules[key].minimumLength}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${translation.getTranslation('ERR_MINIMUM_CHARACTER_NOT_MET')} ${validationRules[key].minimumLength}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].maximumLength && !helpers.isBlank(validationRules[key].maximumLength) && validationRules[key].maximumLength > 0) {
          if (!helpers.isBlank(validateData[key]) && parseInt(validateData[key].length) > validationRules[key].maximumLength) {
            if (throwError) {
              throw {
                Code: 'ERR_MAXIMUM_CHARACTER_LENGTH_INVALID',
                Message: `${translation.getTranslation('ERR_MAXIMUM_CHARACTER_EXCEEDED')} ${validationRules[key].maximumLength}.`
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: `${translation.getTranslation('ERR_MAXIMUM_CHARACTER_EXCEEDED')} ${validationRules[key].maximumLength}.`
              })
            }

            return false
          }
        }

        if (validationRules[key].pattern && !helpers.isBlank(validationRules[key].pattern) && validationRules[key].pattern.value) {
          let RegexTest = new RegExp(validationRules[key].pattern.value, 'i')
          if (!helpers.isBlank(validateData[key]) && !RegexTest.test(validateData[key])) {
            if (throwError) {
              throw {
                Code: 'ERR_PATTERN_INVALID',
                Message: validationRules[key].pattern.message || translation.getTranslation('ERR_INVALID_CHARACTER_PATTERNS')
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: validationRules[key].pattern.message || translation.getTranslation('ERR_INVALID_CHARACTER_PATTERNS')
              })
            }

            return false
          }
        }

        if (validationRules[key].invalid && !helpers.isBlank(validationRules[key].invalid) && validationRules[key].invalid.length > 0) {
          if (!helpers.isBlank(validateData[key]) && validationRules[key].invalid.indexOf(validateData[key]) > -1) {
            if (throwError) {
              throw {
                Code: 'ERR_CONTENT_INVALID',
                Message: validationRules[key].invalid.message || translation.getTranslation('ERR_INVALID_CONTENT')
              }
            }

            if (showNotifications) {
              helpers.createNotification({
                type: 'error',
                title: translation.getTranslation('ERR_VALIDATION'),
                message: validationRules[key].invalid.message || translation.getTranslation('ERR_INVALID_CONTENT')
              })
            }

            return false
          }
        }
      }
    }

    return true
  }
}

const install = (app) => {
  app.config.globalProperties.$validations = validations
}

export { install as default, validations }
