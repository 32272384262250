// SERVICE PARTNER ROUTES
import dashboard from '@/routes/servicepartner/dashboard.js'
import payment from '@/routes/servicepartner/payment.js'
import conversation from '@/routes/servicepartner/conversation.js'
import booking from '@/routes/servicepartner/booking.js'
import calendar from '@/routes/servicepartner/calendar.js'
import service from '@/routes/servicepartner/service.js'
import expert from '@/routes/servicepartner/expert.js'
import location from '@/routes/servicepartner/location.js'
import user from '@/routes/servicepartner/user.js'
import marketing from '@/routes/servicepartner/marketing.js'
import setting from '@/routes/servicepartner/setting.js'
import tag from '@/routes/servicepartner/tag.js'
import integrations from "@/routes/servicepartner/integrations.js";

const servicePartnerRoutes = [
  // START ROUTE DECLARATIONS ORDERED ACCORDINGLY IN NAVIGATIONS
  ...dashboard,
  ...conversation,
  ...expert,
  ...location,
  ...service,
  ...calendar,
  ...booking,
  ...payment,
  ...tag,
  ...user,
  ...marketing,
  ...setting,
  ...integrations
]

export { servicePartnerRoutes }
