import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'dashboard',
    path: '/dashboard/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'chart-area',
        title: translation.getTranslation('PNL_NAV_DASHBOARD', 'Dashboard'),
        activeLink: 'dashboard'
      },
      isSecured: true,
      hasRoles: []
    },
    redirect: '/dashboard/general/',
    routes: [
      {
        name: 'dashboardGeneral',
        path: 'general/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_GENERAL', 'General'),
            activeLink: 'dashboardGeneral'
          },
          isSecured: true,
          hasRoles: []
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/dashboard/DashboardExpertPage.vue')
      }
    ]
  }
]

export default routes
