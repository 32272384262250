import dayjs from 'dayjs'

import { get } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { translation } from '@/utils/translation.js'
import { configs } from '@/utils/configs'

const data = {
  Token: '',
  TokenStartDate: '',
  IsRememberMe: 0,
  Expiration: ''
}

export const state = {
  data
}

export const getters = {
  getToken: (state) => {
    const expirationTime = state?.data?.IsRememberMe === 1 ? configs.tokenExpirationRememberMe : configs.tokenExpiration
    const expirationDate = state.data.TokenStartDate + expirationTime
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (isExpired) {
      state.data.Token = ''
      state.data.TokenStartDate = ''
      return ''
    } else {
      return state.data.Token
    }
  },
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async ({ state, commit, dispatch }, payload) => {
    const userToken = state.data.Token
    const expirationDate = state.data.Expiration
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (userToken && userToken !== '') {
      if (expirationDate == '' || isExpired) {
        try {
          let userInfo = await get('/user/info')

          if (userInfo?.UserKey != '') {
            // prettier-ignore
            userInfo.Expiration = dayjs().add(30, 'minute').valueOf()

            commit('UPDATE_DATA', userInfo)
            return
          }

          throw new Error(translation.getTranslation('ERR_SERVER_NOT_RESPONDING'))
        } catch (err) {
          helpers.catchError(err, true)
        }
      } else {
        return
      }
    }
  }
}

export const user = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
