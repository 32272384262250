<template>
  <f7-popup class="popup-settranslation" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="true">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <section class="form-container">
        <div class="container">
          <f7-list id="formTranslation" class="formContainer" form no-hairlines no-hairlines-between>
            <f7-list-item divider> {{ $t.getTranslation("PNL_LANGUAGE_INFORMATION") }} </f7-list-item>

            <f7-list-input
              v-model:value="formData['LanguageId']"
              type="select"
              name="LanguageId"
              :label="$t.getTranslation('PNL_LANGUAGE')"
              :required="true"
              :minlength="validationRules?.['LanguageId']?.minimumLength"
              :maxlength="validationRules?.['LanguageId']?.maximumLength"
              :placeholder="$t.getTranslation('PNL_SELECT_LANGUAGE')"
              :info="$t.getTranslation('PNL_SELECT_LANGUAGE_INFO')"
              floating-label
              outline
              validate
            >
              <template #content-start>
                <f7-link tabindex="-1" class="form-required">
                  <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                </f7-link>
              </template>

              <option v-for="language in languageList" :key="'cy_' + language.LanguageCode" :value="language.LanguageId">{{ language.LanguageCode }} - {{ language.LanguageName }}</option>
            </f7-list-input>

            <f7-list-item divider> {{ $t.getTranslation("PNL_TRANSLATION_INFORMATION") }} </f7-list-item>

            <template v-for="input in inputGroup?.list" :key="'inp_' + input.value">
              <f7-list-input
                v-model:value="formData[input.value]"
                :class="input.type"
                :type="input.type"
                :name="input.value"
                :required="validationRules?.[input.value]?.required"
                :minlength="validationRules?.[input.value]?.minimumLength"
                :maxlength="validationRules?.[input.value]?.maximumLength"
                :label="input.name"
                :pattern="input.pattern"
                :placeholder="input.placeholder || $t.getTranslation('PNL_TYPE_HERE')"
                :info="$h.getFieldInfo(input)"
                :error-message="input.error ? input.error : ''"
                :disabled="input?.disabled"
                floating-label
                outline
                validate
                :clear-button="!input.hideClearButton"
                @change="input?.onChange ? input['onChange']($event) : ''"
              >
                <template v-if="input?.required === true" #content-start>
                  <f7-link tabindex="-1" class="form-required">
                    <font-awesome-icon :icon="['fas', 'star']" fixed-width />
                  </f7-link>
                </template>

                <template v-if="input.type === 'select'">
                  <template v-for="item in input?.items" :key="'sl_' + item[input?.valueSelector || 'value']">
                    <option
                      v-if="item[input?.valueSelector || 'value'] == '' || !input?.filter || (input?.filter && formData[input?.filter] == '') || (input?.filter && formData[input?.filter] == item[input?.filter])"
                      :value="item[input?.valueSelector || 'value']"
                    >
                      {{ item[input?.nameSelector || "name"] }}
                    </option>
                  </template>
                </template>
              </f7-list-input>
            </template>
          </f7-list>
        </div>
      </section>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large @click="setTranslation">{{ inputGroup?.buttonText || $t.getTranslation("PNL_SET_TRANSLATION") }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";

import { f7 } from "framework7-vue";

import { get } from "@/utils/axios";
import { helpers } from "@/utils/helpers.js";
import { validations } from "@/utils/validations";

export default defineComponent({
  name: "SetTranslationPopupComponent",
  components: {},
  props: {
    backdrop: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["opened", "closed", "set"],
  setup(props, { emit }) {
    let isFetching = false;

    const inputGroup = ref({});
    const languageList = ref([]);

    const formData = reactive({
      LanguageId: "",
    });

    let validationRules = {};

    onMounted(async () => {
      let validationRuleList = await get("/public/validation/list", { Type: "translation" });

      for (let keys in formData) {
        if (validationRuleList && validationRuleList[keys]) {
          validationRules[keys] = validationRuleList[keys];

          if (["LanguageId"].indexOf(keys) > -1) {
            validationRules[keys].required = true;
          }
        }
      }

      await getLanguageList();
    });

    const validate = (showNotifications = false) => {
      let isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications,
      });

      return isValid;
    };

    const getLanguageList = async () => {
      if (isFetching) return;
      isFetching = true;

      languageList.value = await get("/public/language/list");
      isFetching = false;
    };

    const setTranslation = () => {
      let isValid = validate(true);

      if (isValid && formData?.LanguageId > 0) {
        f7.popup.close(".popup-settranslation");

        let translationInfo;
        languageList.value.forEach((translationItem) => {
          if (translationItem.LanguageId == formData.LanguageId) {
            translationInfo = translationItem;
          }
        });

        emit("set", {
          type: inputGroup.value.value,
          data: {
            ...formData,
            LanguageName: translationInfo?.LanguageName,
            LanguageCode: translationInfo?.LanguageCode,
          },
        });
      }
    };

    return {
      formData,
      validationRules,
      inputGroup,
      languageList,
      setTranslation,
      getLanguageList,
    };
  },
  methods: {
    async openPopup(inputGroup, data) {
      const self = this;
      self.inputGroup = inputGroup;

      helpers.clearFormData(self.formData);
      helpers.resetForm("#formTranslation");

      window.setTimeout(() => {
        if (self.formData.LanguageId == "" && self?.languageList?.[0]?.LanguageId) {
          self.formData.LanguageId = self.languageList[0].LanguageId;
        }

        if (data && data?.LanguageId) {
          Object.assign(self.formData, { ...data });
        }

        f7.popup.open(`.popup-settranslation`);
        self.$emit("opened", true);
      }, 10);
    },
    closePopup() {
      f7.popup.close(".popup-settranslation");
      this.$emit("closed", true);
    },
  },
});
</script>

<style scoped>
.popup.popup-settranslation {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-settranslation.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-settranslation.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-settranslation.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-settranslation .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-settranslation .navbar .title {
  padding-left: 10px;
}
.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer-button-container .button {
  width: 100%;
}
</style>
