<template>
  <f7-popup id="editContactInformationPopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="$t.getTranslation('PNL_UPDATE_CONTACT_INFORMATION')" @close-popup="closePopup" />
      </template>

      <FormComponent id="UpdateContactInformationPartial" ref="formComponent" @success-save="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";
import { useStore } from "@/store";
const store = useStore();

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "UpdateContactInformationPartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    const formComponentData = {
      formId: "UpdateContactInformationPartial",
      buttonText: "",
      getLink: "/user/view",
      getParams: {},
      validation: "profile",
      saveLink: "/user/save/contact",
      saveParams: {},
      successUpdateMessage: "Successfully updated contact information.",
    };

    const defaultInputList = [
      {
        name: $t.getTranslation("PNL_CONTACT_INFORMATION"),
        type: "input",
        isRow: true,
        list: [
          {
            name: $t.getTranslation("PNL_MOBILE_CODE"),
            type: "select",
            value: "MobileCode",
            required: true,
            hideClearButton: true,
            items: store.getters["config/getData"]?.mobileCodeList,
            nameSelector: "name",
            valueSelector: "value",
            defaultValue: store.getters["config/getData"]?.defaultMobileCode,
            rowWidth: "col-50",
          },
          { name: $t.getTranslation("PNL_MOBILE_NUMBER"), type: "text", value: "MobileNumber", required: true, rowWidth: "col-50" },
          { name: $t.getTranslation("PNL_CONTACT_NUMBER"), type: "text", value: "ContactNumber", required: false, rowWidth: "col-100" },
          { name: $t.getTranslation("PNL_EMAIL_ADDRESS"), type: "email", value: "Email", required: true, rowWidth: "col-100" },
        ],
      },
    ];

    return {
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup() {
      const self = this;

      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);
      self.formComponentData.buttonText = self.$t.getTranslation("PNL_UPDATE");

      await self.$refs.formComponent.setFormComponentData(self.formComponentData, true);

      f7.popup.open("#editContactInformationPopup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      const self = this;

      if (data?.isSave) {
        store.dispatch("user/fetchData", { force: true });
      }

      f7.popup.close("#editContactInformationPopup");
      self.$emit("closed", data);
    },
  },
});
</script>
