<template>
  <f7-navbar>
    <f7-nav-title> {{ title }} </f7-nav-title>
    <f7-nav-right>
      <f7-link @click="toggleFullScreen">
        <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
        <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
      </f7-link>

      <f7-link @click="closePopup">
        <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
      </f7-link>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import { defineComponent, ref } from "vue";
import { f7 } from "framework7-vue";
import { Dom7 } from "framework7";

import { helpers } from "@/utils/helpers.js";

export default defineComponent({
  name: "NavigationPopupSideComponent",
  components: {},
  props: {
    title: { type: String },
    type: { type: String },
  },
  emits: ["close-popup", "toggle-fullscreen"],
  setup(props, { emit }) {
    const isFullScreen = ref(false);

    let formMasonry = false;

    const closePopup = () => {
      emit("close-popup");
    };

    const toggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value;
      Dom7(".form-container").addClass("hidden");

      if (Dom7(".popup.popup-side.modal-in").length > 0) {
        if (isFullScreen.value === true) {
          Dom7(".popup.popup-side.modal-in").addClass("popup-fullscreen");
        } else {
          Dom7(".popup.popup-side.modal-in").removeClass("popup-fullscreen");
        }
      }

      window.setTimeout(helpers.initFormMasonry, 900);
    };

    f7.on("popupOpened", () => {
      let windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

      if (windowWidth > 1600 && Dom7(".popup.popup-side.modal-in").length > 0) {
        isFullScreen.value = false;
        toggleFullScreen();
      }
    });

    f7.on("popupClosed", () => {
      if (formMasonry) window.setTimeout(helpers.initFormMasonry, 300);
    });

    return {
      isFullScreen,
      closePopup,
      toggleFullScreen,
      formMasonry,
    };
  },
});
</script>
