<template>
  <f7-popup class="popup-selectschedule" :class="inputGroup?.value" :tablet-fullscreen="false" :backdrop="backdrop">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ inputGroup?.title }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="onToggleFullScreen">
            <font-awesome-icon v-if="!isFullScreen" :icon="['far', 'expand']" fixed-width />
            <font-awesome-icon v-if="isFullScreen" :icon="['far', 'compress']" fixed-width />
          </f7-link>

          <f7-link @click="closePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <div class="container">
        <div class="schedule-info">
          <p>{{ $t.getTranslation('PNL_SELECT_SCHEDULE_TOOLTIP_ONE') }}</p>
          <p>{{ $t.getTranslation('PNL_SELECT_SCHEDULE_TOOLTIP_TWO') }}</p>

          <ul>
            <li>8:00 - 9:00</li>
            <li>10:00 - 11:00</li>
            <li>1:00 - 2:00</li>
            <li>3:00 - 4:00</li>
          </ul>

          <p>{{ $t.getTranslation('PNL_SELECT_SCHEDULE_TOOLTIP_THREE') }}</p>
        </div>

        <div class="schedule-options">
          <f7-button fill :tooltip="$t.getTranslation('PNL_TOGGLE_SCHEDULES_TOOLTIP')" @click="onToggleSelectAll">
            {{ !isSelectAllSchedule ? $t.getTranslation('PNL_SELECT_ALL') : $t.getTranslation('PNL_UNSELECT_ALL') }}
          </f7-button>

          <f7-button id="addTime" fill :tooltip="$t.getTranslation('PNL_ADD_TIME_TOOLTIP')" @click.stop="onAddTime('ALL')">
            <font-awesome-icon :icon="['far', 'alarm-plus']" fixed-width />
            {{ $t.getTranslation('PNL_ADD_TIME') }}
          </f7-button>
        </div>

        <div class="schedule-container">
          <div v-for="(schedule, scheduleIndex) in scheduleList" :key="'sch_' + scheduleIndex" class="schedule-item" @click.stop="onChooseSchedule(schedule)">
            <div class="schedule-header">
              <f7-checkbox class="no-ripple" :checked="onCheckIfSelected(schedule)" @click.stop.prevent="onChooseSchedule(schedule)" />
              <h2>{{ schedule.Name }}</h2>
              <f7-button fill :tooltip="$t.getTranslation('PNL_ADD_TIME_CURRENT_TOOLTIP')" @click.stop="onAddTime(schedule.Code)">
                <font-awesome-icon :icon="['far', 'alarm-plus']" fixed-width />
              </f7-button>
            </div>

            <ul v-if="onCheckIfSelected(schedule)" class="schedule-time">
              <template v-if="schedule?.timeList?.length > 0">
                <li v-for="(time, timeIndex) in schedule?.timeList" :key="'sct_' + timeIndex" @click.stop="onDeleteTime(schedule?.timeList, timeIndex)">
                  {{ time.StartTime }} - {{ time.EndTime }}
                  <font-awesome-icon :icon="['far', 'trash']" fixed-width />
                </li>
              </template>
              <template v-else>
                <p>{{ $t.getTranslation('PNL_SELECT_SCHEDULE_TOOLTIP_FOUR') }}</p>
              </template>
            </ul>
          </div>
        </div>
      </div>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large :disabled="isButtonDisabled" @click="onSelectSchedule">{{ inputGroup?.buttonText }}</f7-button>
      </div>
    </f7-page>
  </f7-popup>

  <f7-popup class="popup-selecttime" :tablet-fullscreen="false" :backdrop="backdrop">
    <f7-page>
      <f7-navbar>
        <f7-nav-title> {{ $t.getTranslation('PNL_SELECT_TIME') }} </f7-nav-title>
        <f7-nav-right>
          <f7-link @click="closeSelectTimePopup">
            <font-awesome-icon :icon="['far', 'xmark']" fixed-width />
          </f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list class="formContainer" form no-hairlines no-hairlines-between>
        <f7-list-input
          v-model:value="formData['StartTime']"
          type="text"
          class="schedule-timepicker"
          name="StartTime"
          :label="$t.getTranslation('PNL_START_TIME')"
          :required="true"
          :placeholder="$t.getTranslation('PNL_SELECT_TIME')"
          :info="$t.getTranslation('PNL_START_TIME_INFO')"
          floating-label
          outline
          validate
        >
        </f7-list-input>
        <f7-list-input
          v-model:value="formData['EndTime']"
          type="text"
          class="schedule-timepicker"
          name="EndTime"
          :label="$t.getTranslation('PNL_END_TIME')"
          :required="true"
          :placeholder="$t.getTranslation('PNL_SELECT_TIME')"
          :info="$t.getTranslation('PNL_END_TIME_INFO')"
          floating-label
          outline
          validate
        >
        </f7-list-input>
      </f7-list>

      <div class="footer-button-container">
        <f7-button class="button-select" fill large :disabled="isButtonSelectTimeDisabled" @click="onSelectTimeSchedule">
          {{ $t.getTranslation('PNL_SELECT_TIME') }}
        </f7-button>
      </div>
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, watch, inject } from 'vue'
import { Dom7 } from 'framework7'
import { f7 } from 'framework7-vue'

import { get } from '@/utils/axios'

import { helpers } from '@/utils/helpers'
import { validations } from '@/utils/validations'

import { useStore } from '@/store'
const store = useStore()

export default defineComponent({
  name: 'SelectSchedulePopupComponent',
  components: {},
  props: {
    backdrop: {
      type: Boolean,
      default: true
    }
  },
  emits: ['opened', 'closed', 'selected'],
  setup(props, { emit }) {
    const $t = inject('$translation')

    const inputGroup = ref({})
    const inputGroupParams = ref({})

    const scheduleList = computed(() => store.getters['config/getData']?.scheduleList)
    const selectedScheduleList = ref([])

    const isFullScreen = ref(false)
    const isProcessing = ref(false)
    const isSelectAllSchedule = ref(false)

    const formData = reactive({
      Type: '',
      StartTime: '',
      EndTime: ''
    })

    const isButtonDisabled = computed(() => {
      return selectedScheduleList.value.length <= 0
    })

    const isButtonSelectTimeDisabled = computed(() => {
      let isValid = validate()
      return !isValid
    })

    let validationRules = {}

    onMounted(async () => {
      let validationRuleList = await get('/public/validation/list', { Type: 'time' })

      for (let keys in formData) {
        if (validationRuleList && validationRuleList[keys]) {
          validationRules[keys] = validationRuleList[keys]

          if (['Type', 'StartTime', 'EndTime'].indexOf(keys) > -1) {
            validationRules[keys].required = true
          }
        }
      }

      window.setTimeout(() => {
        helpers.createTimePicker(
          '.schedule-timepicker input',
          (pickerElement, values) => {
            if (values?.length) formData[pickerElement?.inputEl?.attributes?.name?.value] = values.join(':')
          },
          { hasSeconds: false }
        )
      }, 800)
    })

    const validate = (showNotifications = false) => {
      let isValid = validations.validate({
        validateData: formData,
        validationRules: validationRules,
        showNotifications: showNotifications
      })

      if (formData.StartTime > formData.EndTime) {
        isValid = false
        if (showNotifications) {
          helpers.createNotification({
            type: 'error',
            title: $t.getTranslation('ERR_VALIDATION'),
            message: $t.getTranslation('ERR_SELECT_A_VALID_TIME_RANGE')
          })
        }
      }

      return isValid
    }

    const onToggleFullScreen = () => {
      isFullScreen.value = !isFullScreen.value

      if (Dom7('.popup.popup-selectschedule.modal-in').length > 0) {
        if (isFullScreen.value === true) {
          Dom7('.popup.popup-selectschedule.modal-in').addClass('popup-fullscreen')
        } else {
          Dom7('.popup.popup-selectschedule.modal-in').removeClass('popup-fullscreen')
        }
      }
    }

    const onCheckIfSelected = (item, returnIndex = false) => {
      for (let index = 0; index < selectedScheduleList.value.length; index++) {
        let selectedItem = selectedScheduleList.value[index]
        if (selectedItem.Code == item.Code) {
          if (returnIndex) return index
          return true
        }
      }

      return false
    }

    const onChooseSchedule = (item) => {
      let isSelectedIndex = onCheckIfSelected(item, true)
      if (parseInt(isSelectedIndex) >= 0) {
        selectedScheduleList.value.splice(isSelectedIndex, 1)
      } else {
        selectedScheduleList.value.push(item)
      }
    }

    const onToggleSelectAll = () => {
      isSelectAllSchedule.value = !isSelectAllSchedule.value
      for (let counter = 0; counter < scheduleList.value.length; counter++) {
        onChooseSchedule(scheduleList.value[counter])
      }
    }

    const onAddTime = (type) => {
      formData.Type = type
      f7.popup.get('.popup-selecttime').open()
    }

    const onSelectTimeSchedule = () => {
      let timeData = { StartTime: formData.StartTime, EndTime: formData.EndTime }

      for (let schedule of selectedScheduleList.value) {
        if (formData.Type == 'ALL' || schedule.Code == formData.Type) {
          if (schedule.timeList && schedule.timeList.length > 0) {
            for (let time of schedule.timeList) {
              if (time.StartTime != timeData.StartTime && time.EndTime != timeData.EndTime) schedule.timeList.push(timeData)
            }
          } else {
            schedule.timeList = [timeData]
          }
        }
      }

      f7.popup.close('.popup-selecttime')
    }

    const onDeleteTime = (timeList, timeIndex) => {
      timeList.splice(timeIndex, 1)
    }

    const onSelectSchedule = () => {
      for (let schedule of selectedScheduleList.value) {
        if (helpers.isBlank(schedule.timeList) || helpers.isEmpty(schedule.timeList)) {
          helpers.createNotification({
            type: 'error',
            title: $t.getTranslation('ERR_VALIDATION'),
            message: $t.getTranslation('ERR_SELECT_A_VALID_TIME_LIST_EACH_DAY')
          })

          return
        }
      }

      f7.popup.close('.popup-selectschedule')
      emit('selected', { type: inputGroup.value.value, list: selectedScheduleList.value })
    }

    return {
      formData,
      validationRules,
      inputGroup,
      inputGroupParams,
      scheduleList,
      selectedScheduleList,
      isFullScreen,
      isProcessing,
      isButtonDisabled,
      isButtonSelectTimeDisabled,
      isSelectAllSchedule,
      onToggleFullScreen,
      onAddTime,
      onSelectTimeSchedule,
      onDeleteTime,
      onCheckIfSelected,
      onChooseSchedule,
      onToggleSelectAll,
      onSelectSchedule
    }
  },
  methods: {
    async openPopup(inputGroup, selectedList) {
      const self = this
      self.inputGroup = inputGroup
      self.inputGroupParams = inputGroup?.params

      window.setTimeout(() => {
        f7.popup.open(`.popup-selectschedule`)

        self.$emit('opened', true)
      }, 10)
    },
    closePopup() {
      f7.popup.close('.popup-selectschedule')
      this.$emit('closed', true)
    },
    closeSelectTimePopup() {
      f7.popup.close('.popup-selecttime')
    }
  }
})
</script>

<style scoped>
.popup.popup-selecttime {
  --f7-popup-tablet-width: 400px;
  --f7-popup-tablet-height: 350px;
  --f7-popup-tablet-border-radius: 4px;
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;
}
.popup.popup-selecttime .list {
  padding: 0px 20px;
  margin: 20px 0px;
}
.popup.popup-selectschedule {
  --f7-navbar-inner-padding-left: 20px;
  --f7-navbar-inner-padding-right: 20px;

  /* POPUP CONFIG */
  --f7-popup-border-radius: 0px;
  --f7-popup-tablet-width: 500px;
  --f7-popup-tablet-height: 100%;
  --f7-popup-tablet-border-radius: var(--f7-popup-border-radius);
  transform: translate3d(100vh, 0, 0);
  top: auto;
  left: auto;
  bottom: 0px;
  right: 0px;

  transition: all 300ms ease-in-out;
}
.popup.popup-selectschedule.popup-fullscreen {
  --f7-popup-tablet-width: 100%;
  transform: translate3d(100vw, 0, 0);
}
.popup.popup-selectschedule.modal-in {
  transform: translate3d(0, 0, 0);
}
.popup.popup-selectschedule.modal-out {
  transform: translate3d(100vh, 0, 0);
}
.popup.popup-selectschedule .navbar a.link {
  padding: 0 10px;
  min-width: 20px;
}
.popup.popup-selectschedule .navbar .title {
  padding-left: 10px;
}

.popup.popup-selectschedule .container {
  padding: 0px 40px 60px;
  width: calc(100% - 80px);
}
.schedule-info {
  margin-top: 20px;
  border-bottom: 1px solid #f6f6f6;
  padding-bottom: 10px;
}
.schedule-info p {
  margin: 10px 0px;
  line-height: 1.6em;
  font-size: 14px;
}
.schedule-info ul {
  padding: 0 0 0px;
  margin: 5px 0px 0px 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
}
.schedule-info ul li {
  color: #fff;
  padding: 5px 15px;
  border-radius: 4px;
  margin: 0px 5px 5px 0px;
  background: var(--f7-theme-color);
  position: relative;
  font-size: 13px;
  overflow: hidden;
  text-align: center;
  flex-grow: 1;
}
.schedule-options {
  display: flex;
  flex-direction: row;
  margin: 20px 0px 10px;
  justify-content: space-between;
  padding-bottom: 20px;
}
.schedule-options .button {
  width: calc(50% - 10px);
}
.schedule-options .button svg {
  margin-right: 10px;
}

.schedule-container {
  display: flex;
  flex-direction: column;
}
.schedule-item {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 10px;
  border-bottom: 1px solid #f9f9f9;
  padding: 0px 0px 10px;
  cursor: pointer;
}
.schedule-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.schedule-header h2 {
  margin: 0px 20px;
  font-size: 17px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1em;
}
.schedule-header .button {
  margin-left: auto;
  width: 40px;
}
.schedule-header .button svg {
  margin: 0px;
}
.schedule-item ul {
  padding: 0;
  margin: 5px 0px 0px 40px;
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
}
.schedule-item ul li {
  color: #fff;
  padding: 5px 45px 5px 15px;
  border-radius: 4px;
  margin: 0px 10px 10px 0px;
  background: var(--f7-theme-color);
  position: relative;
  overflow: hidden;
}
.schedule-item ul li p {
  margin: 0px;
  width: 80%;
  font-size: 14px;
  line-height: 1.6em;
}
.schedule-item ul li.all {
  padding: 5px 25px;
  text-transform: uppercase;
}

.schedule-item ul li svg {
  margin: 0px;
  background: #a18374;
  position: absolute;
  height: 100%;
  top: 0px;
  right: 0px;
  font-size: 10px;
  padding: 0px 10px;
}

.footer-button-container {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: calc(100% - 55px);
  background: #fff;
  padding: 18px 30px;
  z-index: 999;
  border-top: 1px solid #f1f1f1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.footer-button-container .button {
  width: 100%;
}
</style>
