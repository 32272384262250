import { checkMeta } from '@/routes/@common.js'

const routes = [
  {
    name: 'tagList',
    path: '/tag/list/',
    meta: {
      navigation: {
        isShown: true,
        title: 'Tag',
        activeLink: 'tagList'
      },
      isSecured: true,
      hasRoles: []
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/manage/TagListPage.vue')
  }
]

export default routes
