import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'manage',
    path: '/manage/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'layer-group',
        title: translation.getTranslation('PNL_NAV_MANAGE', 'Manage'),
        activeLink: 'manage'
      },
      isSecured: true,
      hasAnyRoles: ['ADMIN_BANNER', 'ADMIN_CATEGORY', 'ADMIN_TAG', 'ADMIN_FAQ', 'ADMIN_META']
    },
    redirect: '/manage/banner/list/',
    routes: [
      {
        name: 'bannerList',
        path: 'banner/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_BANNER', 'Banner'),
            activeLink: 'bannerList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_BANNER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/BannerListPage.vue')
      },
      {
        name: 'categoryList',
        path: 'category/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_CATEGORY', 'Category'),
            activeLink: 'categoryList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_CATEGORY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/CategoryListPage.vue')
      },
      {
        name: 'tagList',
        path: 'tag/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_TAG', 'Tag'),
            activeLink: 'tagList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_TAG']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/TagListPage.vue')
      },
      {
        name: 'faqList',
        path: 'faq/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_FAQ', 'FAQ'),
            activeLink: 'faqList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_FAQ']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/FAQListPage.vue')
      },
      {
        name: 'newsletterList',
        path: 'newsletter/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_NEWSLETTER', 'Newsletter'),
            activeLink: 'newsletterList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_NEWSLETTER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/NewsletterListPage.vue')
      },
      {
        name: 'contactUsList',
        path: 'contactus/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_CONTACT_US', 'Contact Us'),
            activeLink: 'contactUsList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_CONTACT_US']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/ContactUsListPage.vue')
      },
      {
        name: 'metaList',
        path: 'meta/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_META_KEYWORDS', 'Meta Keywords'),
            activeLink: 'metaList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_META']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/manage/MetaListPage.vue')
      }
    ]
  }
]

export default routes
