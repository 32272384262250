import axios from 'axios'
import { configs } from '@/utils/configs'
import { helpers } from '@/utils/helpers'
import { translation } from '@/utils/translation.js'

import { useStore } from '@/store'
const store = useStore()

const HTTP = axios.create({
  baseURL: configs.baseURL,
  timeout: configs.axiosTimeout
})

HTTP.interceptors.request.use((config) => {
  const userToken = store.getters['user/getToken']

  if (userToken && userToken !== '') {
    config.headers.Authorization = `Bearer ${userToken}`
  }

  return config
})

const get = async (apiLink, queryParams) => {
  try {
    let response = await HTTP.get(`${apiLink}${queryParams && !helpers.isBlank(queryParams) ? helpers.objToQueryString(queryParams || {}, apiLink) : ''}`)

    if (response && response.status === 200 && response?.data?.data) {
      return response.data.data
    }

    throw new Error(translation.getTranslation('ERR_SERVER_NOT_RESPONDING'))
  } catch (err) {
    helpers.catchError(err, true)
  }
}

const post = async (apiLink, postBody) => {
  try {
    helpers.showLoader()
    let response = await HTTP.post(apiLink, postBody)

    if (response && response.status === 200 && response?.data?.data) {
      helpers.hideLoader()
      return response.data.data
    }

    throw new Error(translation.getTranslation('ERR_SERVER_NOT_RESPONDING'))
  } catch (err) {
    helpers.hideLoader()
    helpers.catchError(err, true)
  }
}

const install = (app) => {
  app.config.globalProperties.$HTTP = HTTP
}

export { install as default, HTTP as $HTTP, get, post }
