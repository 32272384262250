import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'service',
    path: '/service/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'user-doctor-message',
        title: translation.getTranslation('PNL_NAV_SERVICES', 'Services'),
        activeLink: 'service'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_SERVICE']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/service/ServiceListPage.vue')
  }
]

export default routes
