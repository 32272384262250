import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'servicePartner',
    path: '/servicepartner/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'store',
        title: translation.getTranslation('PNL_NAV_SERVICE_PARTNERS', 'Service Partners'),
        activeLink: 'servicePartner'
      },
      isSecured: true,
      hasAnyRoles: ['ADMIN_SERVICEPARTNER', 'ADMIN_SERVICEPARTNER_LOCATION', 'ADMIN_SERVICEPARTNER_USER']
    },
    redirect: '/servicepartner/list/',
    routes: [
      {
        name: 'servicePartnerList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_PARTNER_LIST', 'Partner List'),
            activeLink: 'servicePartnerList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SERVICEPARTNER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/servicepartner/ServicePartnerListPage.vue')
      },
      {
        name: 'servicePartnerLocationList',
        path: 'location/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_LOCATION_LIST', 'Location List'),
            activeLink: 'servicePartnerLocationList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SERVICEPARTNER_LOCATION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/servicepartner/ServicePartnerLocationListPage.vue')
      },
      {
        name: 'servicePartnerUserList',
        path: 'user/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_USER_LIST', 'User List'),
            activeLink: 'servicePartnerUserList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SERVICEPARTNER_USER']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/servicepartner/ServicePartnerUserListPage.vue')
      }
    ]
  }
]

export default routes
