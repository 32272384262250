import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'serviceLocationList',
    path: '/service/location/list/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'map',
        title: 'Locations',
        title: translation.getTranslation('PNL_NAV_LOCATIONS', 'Locations'),
        activeLink: 'serviceLocationList'
      },
      isSecured: true,
      hasRoles: ['SERVICEPARTNER_LOCATION']
    },
    beforeEnter: [checkMeta],
    asyncComponent: () => import('@/pages/servicepartner/ServicePartnerLocationListPage.vue')
  }
]

export default routes
