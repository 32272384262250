import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'setting',
    path: '/setting/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'gear',
        title: translation.getTranslation('PNL_NAV_SETTINGS', 'Settings'),
        activeLink: 'setting'
      },
      isSecured: true,
      hasRoles: ['ADMIN_SETTING']
    },
    redirect: '/setting/country/list/',
    routes: [
      {
        name: 'countryList',
        path: 'country/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_COUNTRY', 'Country'),
            activeLink: 'countryList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SETTING_COUNTRY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/CountryListPage.vue')
      },
      {
        name: 'currencyList',
        path: 'currency/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_CURRENCY', 'Currency'),
            activeLink: 'currencyList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SETTING_CURRENCY']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/CurrencyListPage.vue')
      },
      {
        name: 'languageList',
        path: 'language/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_LANGUAGE', 'Language'),
            activeLink: 'languageList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SETTING_LANGUAGE']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/LanguageListPage.vue')
      },
      {
        name: 'translationList',
        path: 'translation/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_TRANSLATION', 'Translation'),
            activeLink: 'translationList'
          },
          isSecured: true,
          hasRoles: ['ADMIN_SETTING_TRANSLATION']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/setting/TranslationListPage.vue')
      }
      // {
      //   name: 'guidelines',
      //   path: 'guidelines/',
      //   meta: {
      //     navigation: {
      //       isShown: true,
      //       title: translation.getTranslation('PNL_NAV_GUIDELINES','Guidelines'),
      //       activeLink: 'guidelines'
      //     },
      //     isSecured: true,
      //     hasRoles: ['ADMIN_SETTING_GUIDELINES']
      //   },
      //   beforeEnter: [checkMeta],
      //   asyncComponent: () => import('@/pages/setting/GuidelinesPage.vue')
      // }
    ]
  }
]

export default routes
