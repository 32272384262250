<template>
  <f7-popup id="profileChangePasswordPopup" class="popup-side popup-save" :tablet-fullscreen="false" :backdrop="true" :close-by-backdrop-click="true">
    <f7-page>
      <template #fixed>
        <NavigationPopupSideComponent :title="title" @close-popup="closePopup" />
      </template>

      <FormComponent id="ChangePasswordPartial" ref="formComponent" @success-save="closePopup" />
    </f7-page>
  </f7-popup>
</template>

<script>
import { defineComponent, ref, inject } from "vue";
import { f7 } from "framework7-vue";

import { helpers } from "@/utils/helpers.js";

import NavigationPopupSideComponent from "@/components/NavigationPopupSideComponent.vue";
import FormComponent from "@/components/FormComponent.vue";

export default defineComponent({
  name: "ChangePasswordPartial",
  components: {
    NavigationPopupSideComponent,
    FormComponent,
  },
  props: {},
  emits: ["opened", "closed"],
  setup(props, { emit }) {
    const $t = inject("$translation");

    const title = ref("");

    const formComponentData = {
      formId: "ChangePasswordPartial",
      buttonText: $t.getTranslation("PNL_CHANGE_PASSWORD"),
      validation: "password",
      saveLink: "/user/update/password",
      successUpdateMessage: $t.getTranslation("MSG_SUCCESSFULLY_UPDATED_ACCOUNT_PASSWORD"),
    };

    const defaultInputList = [
      {
        name: "",
        type: "input",
        list: [
          { name: $t.getTranslation("PNL_CURRENT_PASSWORD"), type: "password", value: "CurrentPassword", required: true, hideClearButton: true },
          { name: $t.getTranslation("PNL_NEW_PASSWORD"), type: "password", value: "NewPassword", required: true, hideClearButton: true },
          { name: $t.getTranslation("PNL_CONFIRM_PASSWORD"), type: "password", value: "ConfirmPassword", required: true, hideClearButton: true },
        ],
      },
    ];

    return {
      title,
      formComponentData,
      defaultInputList,
    };
  },
  methods: {
    async openPopup(data) {
      const self = this;
      self.formComponentData.inputList = helpers.cloneDeep(self.defaultInputList);
      await self.$refs.formComponent.setFormComponentData(self.formComponentData, true);

      self.title = self.$t.getTranslation("PNL_CHANGE_PASSWORD");

      f7.popup.open("#profileChangePasswordPopup");
      self.$emit("opened", true);
    },
    closePopup(data) {
      const self = this;
      f7.popup.close("#profileChangePasswordPopup");
      self.$emit("closed", data);
    },
  },
});
</script>
