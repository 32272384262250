import { checkMeta } from '@/routes/@common.js'
import { translation } from '@/utils/translation.js'

const routes = [
  {
    name: 'expert',
    path: '/expert/',
    meta: {
      navigation: {
        isShown: true,
        icon: 'person-booth',
        title: translation.getTranslation('PNL_NAV_EXPERTS', 'Experts'),
        activeLink: 'expert'
      },
      isSecured: true,
      hasAnyRoles: ['SERVICEPARTNER_EXPERT']
    },
    redirect: '/expert/list/',
    routes: [
      {
        name: 'expertList',
        path: 'list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_EXPERT_LIST', 'Expert List'),
            activeLink: 'expertList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_EXPERT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/expert/ExpertListPage.vue')
      },
      {
        name: 'expertInvitationList',
        path: 'invitation/list/',
        meta: {
          navigation: {
            isShown: true,
            title: translation.getTranslation('PNL_NAV_INVITATION_LIST', 'Invitation List'),
            activeLink: 'expertInvitationList'
          },
          isSecured: true,
          hasRoles: ['SERVICEPARTNER_EXPERT']
        },
        beforeEnter: [checkMeta],
        asyncComponent: () => import('@/pages/expert/ExpertInvitationListPage.vue')
      }
    ]
  }
]

export default routes
