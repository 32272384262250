import dayjs from 'dayjs'

import { $HTTP } from '@/utils/axios'
import { helpers } from '@/utils/helpers'
import { translation } from '@/utils/translation.js'

const data = {
  statusList: [],
  userStatusList: [],
  refundRequestStatusList: [],
  refundStatusList: [],
  expirationDate: ''
}

export const state = {
  data
}

export const getters = {
  getData: (state) => {
    return state.data
  }
}

export const mutations = {
  UPDATE_DATA(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  RESET_DATA(state) {
    state.data = { ...data }
  }
}

export const actions = {
  setData: (context, payload) => {
    context.commit('UPDATE_DATA', payload)
  },
  resetData: (context) => {
    context.commit('RESET_DATA')
  },
  fetchData: async (context, payload) => {
    const expirationDate = context.state.data.expirationDate
    const currentDate = dayjs().valueOf()

    let isExpired = currentDate > expirationDate
    if (payload?.force) {
      isExpired = true
    }

    if (expirationDate == '' || isExpired) {
      try {
        const res = await $HTTP.get('/public/config')

        if (res && res.status === 200 && res.data && res.data.data) {
          const configInfo = res.data.data
          // prettier-ignore
          configInfo.expirationDate = dayjs().add(1, 'hour').valueOf()

          context.commit('UPDATE_DATA', configInfo)
          return
        }

        throw new Error(translation.getTranslation('ERR_SERVER_NOT_RESPONDING'))
      } catch (err) {
        helpers.catchError(err, true)
      }
    } else {
      return
    }
  }
}

export const config = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
