import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import { configs } from '@/utils/configs'

import SecureLS from '@/libs/secure-ls'

// STORE DECLARATIONS
import { config } from './config'
import { translation } from './translation'
import { page } from './page'
import { form } from './form'
import { user } from './user'
import { socket } from './socket'

const secureLocalStorage = new SecureLS({
  isLocalStorage: true,
  isCompression: configs?.encryption?.enabled,
  encodingType: configs?.encryption?.enabled && configs?.encryption?.encodingType ? configs?.encryption?.encodingType : '',
  encryptionSecret: configs.encryption.secret
})

const secureSessionStorage = new SecureLS({
  isLocalStorage: false,
  isCompression: configs?.encryption?.enabled,
  encodingType: configs?.encryption?.enabled && configs?.encryption?.encodingType ? configs?.encryption?.encodingType : '',
  encryptionSecret: configs.encryption.secret
})

const persistedLocalStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['translation'],
  storage: {
    getItem: (key) => secureLocalStorage.get(key),
    setItem: (key, value) => secureLocalStorage.set(key, value),
    removeItem: (key) => secureLocalStorage.remove(key)
  }
})

const persistedSessionStorage = createPersistedState({
  key: configs.tokenSession,
  paths: ['page', 'user', 'config'],
  storage: {
    getItem: (key) => secureSessionStorage.get(key),
    setItem: (key, value) => secureSessionStorage.set(key, value),
    removeItem: (key) => secureSessionStorage.remove(key)
  }
})

export const store = createStore({
  modules: {
    config,
    translation,
    page,
    form,
    user,
    socket
  },
  plugins: [persistedLocalStorage, persistedSessionStorage]
})

export function useStore() {
  return store
}

export default store
